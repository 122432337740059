import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: "/",
    redirect: "/user/login",
  },
  {
    path: "/user/login",
    name: 'login',
    meta: {
      requiresAuth: false,
      keepAlive: false,
      title: "登录",
    },
    component: () => import("@VIE/user/login.vue"),
  },
  {
    path: "/user/register",
    name: 'register',
    meta: {
      requiresAuth: false,
      keepAlive: false,
      title: "注册",
    },
    component: () => import("@VIE/user/register.vue"),
  },
  {
    path: "/user/oauth2",
    name: 'oauth2',
    meta: {
      requiresAuth: false,
      keepAlive: false,
      title: "授权",
    },
    component: () => import("@VIE/user/register.vue"),
  },


  {
    path: "/navBar",
    redirect: '/Statistics',
    meta: {
      requiresAuth: true,
      keepAlive: false,
      title: "nav",
    },
    component: () => import("@/layout/navBar.vue"),
    children: [
      {
        path: "/home",
        name: 'home',
        meta: {
          requiresAuth: true,
          keepAlive: false,
          title: "订单中心",
        },
        component: () => import("@VIE/order/index.vue"),
      },
      {
        path: "/order/:order_no",
        name: 'orderInfo',
        meta: {
          requiresAuth: true,
          keepAlive: false,
          title: "订单详情",
        },
        component: () => import("@VIE/order/info.vue"),
      },
      {
        path: "/mine",
        name: 'mine',
        meta: {
          requiresAuth: true,
          keepAlive: false,
          title: "我的",
        },
        component: () => import("@VIE/mine/index.vue"),
      },
      {
        path: "/employee",
        name: 'employee',
        meta: {
          requiresAuth: true,
          keepAlive: false,
          title: "员工管理",
        },
        component: () => import("@VIE/employee/index.vue"),
      },
      {
        path: "/employee/add",
        name: 'employeeAdd',
        meta: {
          requiresAuth: true,
          keepAlive: false,
          title: "添加员工",
        },
        component: () => import("@VIE/employee/add.vue"),
      },
      // {
      //   path: "/employee/:mobile/edit",
      //   name: 'employeeEdit',
      //   meta: {
      //     requiresAuth: true,
      //     keepAlive: false,
      //     title: "编辑员工",
      //   },
      //   component: () => import("@VIE/employee/edit.vue"),
      // },
    ]
  },

  /**
   * 404 Page
   */
  {
    path: "*",
    meta: {
      title: "页面找不到了～！～",
    },
    component: () => import("@VIE/404.vue"),
  },
]

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  // @ts-ignore
  document.title = to.meta.title || "马小二 —— 便利店";
  return next();
});


export default router
